import React, { Component } from 'react';
import Loading from './Loading';
import LogIn from './LogIn';
import { connect } from 'react-redux';
import { silentAuth } from '../store/AuthAction';
import AcceptPermissions from './AcceptPermissions';
import ManagingSystemMismatch from './ManagingSystemMismatch';


class Private extends Component {

    componentDidMount() {
       this.props.silentAuth();
    };

    render() {
        const { needLogin, token, needConsent, waitingForConsent, allowedManagingSystem} = this.props;
        const ChildComponent = this.props.component;
       
        if (needLogin) {
            return <LogIn />;
        } else if (needConsent) {
            return <AcceptPermissions />;
        } else if (allowedManagingSystem === false) { // Because undefined
            return <ManagingSystemMismatch />
        } else if (!token || waitingForConsent) {
            return <Loading classNameBg='spinner-large_bg' classNameInner='spinner-large' />;
        } else {
            return <ChildComponent />;
        }
    }
}

const mapStatetoProps = state => ({
    token: state.auth.token,
    needLogin: state.auth.needLogin,
    needConsent: state.auth.needConsent,
    waitingForConsent: state.auth.waitingForConsent,
    allowedManagingSystem: state.auth.allowedManagingSystem
})

export default connect(mapStatetoProps, { silentAuth })(Private);
import axios from 'axios';
const GET_HOME_INFO = 'GET_HOME_INFO';

export const GetHomeInfo = (token) => dispatch => {

    const url = '/api/home'
    axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(res =>
            dispatch({
                type: GET_HOME_INFO,
                homeInfo: res.data
            })
        )
}

const initialState = {
    homeInfo: [],
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case GET_HOME_INFO:
            return {
                ...state,
                homeInfo: action.homeInfo
            }
        default:
            return state;
    }
}
import axios from 'axios';
const ADMIN_GET_CUSTOMERS = 'ADMIN_GET_CUSTOMERS';
const ADMIN_GETEXPORT = 'ADMIN_GETEXPORT';
const ADMIN_GETIMPORT = 'ADMIN_GETIMPORT';
const ADMIN_GETEXPORT_ERRORS = 'ADMIN_GETEXPORT_ERRORS';
const ADMIN_GETIMPORT_ERRORS = 'ADMIN_GETIMPORT_ERRORS';

export const AdminGetCustomers = (token) => dispatch => {

    const url = '/api/admin/customer'
    axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(res =>
            dispatch({
                type: ADMIN_GET_CUSTOMERS,
                customers: res.data,
            })
        );
};

export const AdminGetRecipientExport = (token, exportId) => dispatch => {

    dispatch({
        type: ADMIN_GETEXPORT,
        export: undefined
    });

    const url = '/api/admin/customer/export/' + exportId;
    axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(res =>
            dispatch({
                type: ADMIN_GETEXPORT,
                export: res.data,
            })
        );
};

export const AdminGetImport = (token, importId) => dispatch => {

    dispatch({
        type: ADMIN_GETIMPORT,
        import: undefined
    });

    const url = '/api/admin/customer/import/' + importId;
    axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(res =>
            dispatch({
                type: ADMIN_GETIMPORT,
                import: res.data,
            })
        );
};

export const AdminGetExportErrors = (token, exportStatisticsId) => dispatch => {

    const url = '/api/admin/customer/export/errors/' + exportStatisticsId;
    axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(res =>
            dispatch({
                type: ADMIN_GETEXPORT_ERRORS,
                id: exportStatisticsId,
                exportErrors: res.data,
            })
        );
};

export const AdminGetImportErrors = (token, importStatisticsId) => dispatch => {

    const url = '/api/admin/customer/import/errors/' + importStatisticsId;
    axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(res =>
            dispatch({
                type: ADMIN_GETIMPORT_ERRORS,
                id: importStatisticsId,
                importErrors: res.data,
            })
        );
};

const initialState = {
    customers: undefined,
    export: undefined,
    import: undefined,
    exportErrors: {}, 
    importErrors: {}
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case ADMIN_GET_CUSTOMERS:
            return {
                ...state,
                customers: action.customers,
            };
        case ADMIN_GETEXPORT:
            return {
                ...state,
                export: action.export,
            };
        case ADMIN_GETIMPORT:
            return {
                ...state,
                import: action.import,
            };
        case ADMIN_GETEXPORT_ERRORS:
            return {
                ...state,
                exportErrors: {
                    ...state.exportErrors,
                    [action.id]: action.exportErrors
                }
            };
        case ADMIN_GETIMPORT_ERRORS:
            return {
                ...state,
                importErrors: {
                    ...state.importErrors,
                    [action.id]: action.importErrors
                }
            };
        default:
            return state;
    }
    
};
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { microsoftAuth } from '../store/AuthAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/LogIn.css';


class LogIn extends Component {

    logInLink = () => {
        this.props.microsoftAuth();
    }

    render() {
        return (
            <div className="login">
                <div className="login_box left">
                    <div>
                        <img className="logo" src="../Images/logo_neg.png" alt="" />
                        <h1>Skolsynk för Microsoft</h1>
                        <p>Synkar skolor, elever, lärare och grupper från Microsoft till läromedelsleverantörer.</p>
                    </div>
                </div>
                <div className="login_box right">
                    <div>
                        <h2>Logga in eller registrera</h2>
                        <div className="flex">
                            <button className="login__provider--microsoft login_btn" onClick={() => this.props.microsoftAuth()}>
                                <FontAwesomeIcon className="login__provider-icon" icon={['fab', 'microsoft']} /> Logga in
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({

});

export default withRouter(connect(mapStateToProps, { microsoftAuth })(LogIn));

import React, { Component } from 'react';
import { AdminGetImport } from '../../store/Admin/AdminCustomerAction';
import { connect } from 'react-redux';
import Loading from '../Loading';
import AdminTableComponent from './AdminTableComponent';
import { Link } from 'react-router-dom';


class AdminImport extends Component {

    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.AdminGetImport(this.props.adminToken, id)
    }

    render() {
        const { imports } = this.props;

        if (!imports) {
            return (<Loading />)
        } else {
            return (
                <div>
                    <p><Link to={'/admin'}>Tillbaka till admin</Link></p>

                    <div className="admin_customer_info">
                        <p><span>Kund</span><span>{imports.customerName}</span></p>
                        <p><span>Tjänst</span><span>Import</span></p>
                    </div>

                    <p className="latest">Senaste importen</p>

                    <AdminTableComponent
                        isExport={false}
                        start={imports.start}
                        stop={imports.stop}
                        statistics={imports.statistics}
                    />
                </div>
            )
        }
    }
}


const mapStatetoProps = state => ({
    adminToken: state.adminAuth.adminToken,
    imports: state.admin.import,
});

export default connect(mapStatetoProps, { AdminGetImport })(AdminImport);

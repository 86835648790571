import React, { Component } from "react";

export default class SchoolUnitConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipientSchoolUnit: props.recipientSchoolUnit
        }
    }

    onChange = (active, schoolUnitCode) => {
        const recipientSchoolUnit = {
            schoolUnitId: this.props.schoolUnit.id,
            active,
            schoolUnitCode
        };

        this.setState({ recipientSchoolUnit });
        this.props.onChange(recipientSchoolUnit);
    }

    getSelectClassName = (active, schoolUnitCode) => {
        const { scbSchoolUnits } = this.props;
        const isSchoolUnitCodeValid = scbSchoolUnits && scbSchoolUnits.some(su => su.schoolUnitCode === schoolUnitCode);

        if (isSchoolUnitCodeValid) {
            return 'school-unit-select';
        } else if (active || schoolUnitCode) {
            return 'school-unit-select redAlert';
        } else {
            return 'school-unit-select';
        }
    }

    getSchoolUnitCodeOptions = (schoolUnitCode) => {
        const { schoolUnit, scbSchoolUnits } = this.props;
        const options = [<option key='empty' value='' >{scbSchoolUnits ? 'Välj' : 'Skolenhetskod saknas'}</option>];

        if (scbSchoolUnits) {
            if (schoolUnitCode && !scbSchoolUnits.some(su => su.schoolUnitCode === schoolUnitCode)) {
                options.push(<option key='invalid' value={schoolUnitCode} >{schoolUnit.title}, {schoolUnitCode}</option>)
            }

            options.push(...scbSchoolUnits.map(su => <option key={su.id} value={su.schoolUnitCode}>{su.title}, {su.schoolUnitCode}</option>))
        }

        return options;
    }

    render() {
        const { schoolUnit } = this.props;
        const { recipientSchoolUnit } = this.state;
        const active = recipientSchoolUnit ? recipientSchoolUnit.active : false;
        const schoolUnitCode = recipientSchoolUnit ? recipientSchoolUnit.schoolUnitCode : schoolUnit.schoolUnitCode;
        const selectClassName = this.getSelectClassName(active, schoolUnitCode);
        const schoolUnitCodeOptions = this.getSchoolUnitCodeOptions(schoolUnitCode);

        return <div className="school">
            <span className="school_column">
                <label>
                    <input type="checkbox" id={schoolUnit.id} checked={active} onChange={(e) => this.onChange(e.target.checked, schoolUnitCode)} />
                    {schoolUnit.displayName}
                </label>
            </span>
            <span className="schoolunit_code">
                <select onChange={(e) => this.onChange(active, e.target.value)} defaultValue={schoolUnitCode} className={selectClassName}>
                    {schoolUnitCodeOptions}
                </select>
            </span>
        </div>;
    }
}
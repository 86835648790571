import React, { Component } from 'react';
import { TopBar } from './TopBar';
import { withRouter } from 'react-router-dom';


class NotFound extends Component {

    toStartpage = () => {
        this.props.history.push('/');
    }

    render() {
        return (
            <div>
                <TopBar />
                <div className="notfound">
                    <h1>Hoppsan..! Sidan du sökte finns inte</h1>
                    <button className="login_btn notfound_btn" onClick={this.toStartpage}>Till startsidan</button>
                </div>
            </div>
        )
    }
}
export default withRouter(NotFound);
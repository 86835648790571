import axios from 'axios';
const GET_REPORT = 'GET_REPORT';

export const GetReport = (token, id) => dispatch => {

    dispatch({
        type: GET_REPORT,
        fetchingReport: true
    })

    const url = '/api/report/' + id + '/latest?completed=true';
    axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(res =>
            dispatch({
                type: GET_REPORT,
                report: res.data,
                fetchingReport: false
            })
        )
}


const initialState = {
    report: {},
    fetchingReport: true
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case GET_REPORT:
            return {
                ...state,
                report: action.report,
                fetchingReport: action.fetchingReport
            }
        default:
            return state;
    }
}

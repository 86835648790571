import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import * as AuthAction from './AuthAction';
import * as SchoolUnitAction from './SchoolUnitAction';
import * as RecipientAction from './RecipientAction';
import * as HomeAction from './HomeAction';
import * as ReportAction from './ReportAction';
import * as ScbAction from './ScbAction';
import * as AdminCustomerAction from './Admin/AdminCustomerAction';
import * as AdminAuthAction from './Admin/AdminAuthAction';
import * as AdminResponsibleAuthority from './Admin/AdminResponsibleAuthority';

export default function configureStore(history, initialState) {
    const reducers = {
        auth: AuthAction.reducer,
        schoolUnit: SchoolUnitAction.reducer,
        recipient: RecipientAction.reducer,
        home: HomeAction.reducer,
        report: ReportAction.reducer,
        scb: ScbAction.reducer,
        admin: AdminCustomerAction.reducer,
        adminAuth: AdminAuthAction.reducer,
        responsibleAuthority: AdminResponsibleAuthority.reducer
    };

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const rootReducer = combineReducers({
        ...reducers,
        routing: routerReducer
    });

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
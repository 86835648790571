import axios from 'axios';
const GET_SCHOOLUNITS = 'GET_SCHOOLUNITS';

export const GetSchoolUnits = (token) => dispatch => {

    dispatch({
        type: GET_SCHOOLUNITS, 
        fetching: true
    })

    const url = '/api/schoolunit'
    axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(res =>
            dispatch({
                type: GET_SCHOOLUNITS,
                schoolUnits: res.data,
                fetching: false
            })
        );
};

const initialState = {
    schoolUnits: {},
    fetching: false
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case GET_SCHOOLUNITS:
            return {
                ...state,
                schoolUnits: action.schoolUnits,
                fetching: action.fetching
            };
        default:
            return state;
    }

};

import React, { Component } from 'react';
import '../styles/SavingFeedback.css';
import { connect } from 'react-redux';
import Loading from './Loading';


class SavingFeedback extends Component {
    
    render() {
        const { posting, couldNotSave } = this.props;
        return (
            <div className="saving_frame">
                {posting && <Loading />}
                {!posting && couldNotSave && <span className="saving_text"><span className="saving_check check_not_ok">&#10007;</span><p>Misslyckades</p></span>}
                {!posting && !couldNotSave && <span className="saving_text"><span className="saving_check check_ok">&#10003;</span><p>Sparat</p></span>}
            </div>
        )
    }
}

const mapStatetoProps = state => ({
    posting: state.recipient.posting,
    couldNotSave: state.recipient.couldNotSave
})

export default connect(mapStatetoProps)(SavingFeedback);

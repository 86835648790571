import MsalApi from '../services/MsalApi';
import axios from 'axios';

const UPDATE_AUTH_STATUS = 'UPDATE_AUTH_STATUS';
const LOGOUT = 'LOGOUT';

const ALLOWED_MANAGING_SYSTEM = 'MSGraphEducation';

export const silentAuth = () => async dispatch => {
    let tokenResponse;
    try {
        tokenResponse = await MsalApi.idTokenSilent();
    } catch (err) {
        console.error(err)
        return dispatch({
            type: UPDATE_AUTH_STATUS,
            needLogin: true, 
            needConsent: false
        });
    }

    await register(tokenResponse, dispatch);
};

const timeout = (ms) => new Promise(resolve => setTimeout(resolve, ms)); 

const register = async (tokenResponse, dispatch) => { 
    let dispatchState = {
        type: UPDATE_AUTH_STATUS,
        token: null,
        needLogin: false,
        needConsent: false,
        errorCode: null,
        consentUrl: null,
        waitingForConsent: false,
        managingSystem: null,
        allowedManagingSystem: false
    }

    const start = new Date().getTime();
    while ((new Date().getTime() - start) < 5 * 60 * 1000) {
        try {
            var res = await axios({
                method: 'POST',
                url: `/api/register`,
                headers: {
                    Authorization: `Bearer ${tokenResponse.idToken.rawIdToken}`
                }
            })

            dispatchState.token = tokenResponse.idToken.rawIdToken;
            dispatchState.managingSystem = res.data.importSystem;
            dispatchState.allowedManagingSystem = res.data.importSystem && res.data.importSystem === ALLOWED_MANAGING_SYSTEM;
            break;
        } catch (err) {
            dispatchState.errorCode = err.response.status;
            dispatchState.consentUrl = err.response.headers.location;

            if (err.response.status === 428 && window.location.search.indexOf('admin_consent=True') !== -1) {
                dispatchState.waitingForConsent = true;
                dispatch(dispatchState);
                await timeout(5000);
            }  else {
                dispatchState.needConsent = true;
                break;
            }
        }
    }

    dispatch(dispatchState);
}

export const microsoftAuth = () => async dispatch => {
    const tokenResponse = await MsalApi.idTokenPopup();
    dispatch({
        type: UPDATE_AUTH_STATUS,
        needLogin: false
    })
    await register(tokenResponse, dispatch);
};

export const signOut = () => async dispatch => {
    await MsalApi.logout();

    return dispatch({
        type: LOGOUT
    });
};

const initialState = {
    token: undefined,
    needLogin: false,
    needConsent: false,
    errorCode: undefined,
    consentUrl: undefined,
    waitingForConsent: false
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case UPDATE_AUTH_STATUS:
            return {
                ...state,
                token: action.token,
                needLogin: action.needLogin,
                needConsent: action.needConsent,
                errorCode: action.errorCode, 
                consentUrl: action.consentUrl,
                managingSystem: action.managingSystem,
                allowedManagingSystem: action.allowedManagingSystem
            };
        case LOGOUT:
            return {
                ...state,
                token: undefined,
                needLogin: true,
                needConsent: true
            };
        default:
            return state;
    }

};


import React, { Component } from 'react';
import '../styles/ToggleSwitch.css';

export class ToogleSwitch extends Component {
    render() {
        const props = this.props;

        return (
            <div>
                <div className="toggle-switch">
                    <input
                        type="checkbox"
                        className="toggle-switch-checkbox"
                        name="toggleSwitch"
                        id={props.id}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                    />
                    <label className="toggle-switch-label" htmlFor={props.id}>
                        <span className="toggle-switch-inner"
                            data-yes={props.yes}
                            data-no={props.no}
                        />
                        <span className="toggle-switch-switch" />
                    </label>
                </div>
            </div>
        );
    }
}
import React, { Component } from 'react';
import Loading from './Loading';
import { connect } from 'react-redux';
import { GetRecipients } from '../store/RecipientAction';
import { GetHomeInfo } from '../store/HomeAction';
import '../styles/Home.css';
import { Link } from 'react-router-dom';

class Home extends Component {

    componentDidMount() {
        this.props.GetRecipients(this.props.token);
        this.props.GetHomeInfo(this.props.token);
    }

    getEnvironmentString(configuration) {
        if (configuration) {
            return configuration.environment === 0 ? 'Testmiljö' : 'Produktionsmiljö';
        }

        return 'Ej konfigurerad'
    }

    render() {
        const { recipients, homeInfo } = this.props;

        const schoolUnits = homeInfo.schoolUnits ? homeInfo.schoolUnits : '0';
        const activities = homeInfo.activities ? homeInfo.activities : '0';
        const users = homeInfo.users ? homeInfo.users : '0';
        const teachers = homeInfo.teachers ? homeInfo.teachers : '0';
        const students = homeInfo.students ? homeInfo.students : '0';

        return (
            <div>
                {!recipients && <Loading />}
                <div className="home_components">
                    <div className="home_component">
                        <h4>Tjänster</h4>

                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Aktiv</th>
                                    <th>Miljö</th>
                                    <th>Synkstatus</th>
                                </tr>
                            </thead>
                            <tbody>
                                {recipients.map(recipient => {
                                    let isActive = recipient.configuration ? recipient.configuration.active : false;
                                    let active = isActive ? 'Ja' : 'Nej';
                                    let environment = this.getEnvironmentString(recipient.configuration);
                                    let url = /synk/ + recipient.id;
                                    let syncStatus = '-';
                                    if (homeInfo !== undefined
                                        && homeInfo.errorCounts !== undefined
                                        && homeInfo.errorCounts[recipient.id] !== undefined) {

                                        syncStatus = homeInfo.errorCounts[recipient.id] > 0 ?
                                            <span style={{ color: 'red' }}>✗</span> :
                                            <span style={{ color: 'green' }}>✓</span>
                                    }

                                    return (
                                        <tr key={recipient.id}>
                                            <td className="home_displayName"><Link to={url}>{recipient.displayName}</Link></td>
                                            <td>{active}</td>
                                            <td>{environment}</td>
                                            <td><span style={{ marginLeft: '32px' }}>{syncStatus}</span></td>
                                        </tr>
                                    )
                                }
                                )}
                            </tbody>
                        </table>
                    </div>
                    {homeInfo &&
                        <div className="home_component">
                        <h4>Inläst data</h4>
                        <div><span className="w100">Skolor:</span><span>{schoolUnits}</span></div>
                        <div><span className="w100">Grupper:</span><span>{activities}</span></div>
                        <div><span className="w100">Användare:</span><span>{users}</span></div>
                        <div><span className="w100" style={{paddingLeft: '10px'}}>varav Lärare:</span><span>{teachers}</span></div>
                        <div><span className="w100" style={{paddingLeft: '10px'}}>varav Studenter:</span><span>{students}</span></div>
                    </div>
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    recipients: state.recipient.recipients,
    token: state.auth.token,
    homeInfo: state.home.homeInfo
})

export default connect(mapStateToProps, { GetRecipients, GetHomeInfo })(Home);

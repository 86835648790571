import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class Modal extends Component {

    componentDidMount() {
        document.addEventListener('mousedown', this.handleMouseDown, false);
        document.addEventListener('keydown', this.handleKeyDown, false);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleMouseDown, false);
        document.removeEventListener('keydown', this.handleKeyDown, false);
    }

    handleMouseDown = (e) => {
        if (this.mainNode && !this.mainNode.contains(e.target)) {
            this.props.close();
        }
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 27) {
            this.props.close();
        }
    }

    render = () => {
        const { close, children, title } = this.props;

        return (
            <div className="admin_error_modal" onMouseDown={this.handleMouseDown} onKeyDown={this.handleKeyDown}>
                <div ref={node => this.mainNode = node}>
                    <div className="modal__close" onClick={close}>
                        <FontAwesomeIcon icon="times" />
                    </div>
                    <h2 className="modal__title">
                        {title ? title : '\u00A0'}
                    </h2>
                    <div className="modal__content">
                        {children}
                        <button onClick={close}>Stäng</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Modal;

import React, { Component } from 'react';
import { NavItem, NavLink, Nav } from 'reactstrap';
import '../../styles/NavMenu.css';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { signOutAdmin } from '../../store/Admin/AdminAuthAction';
import { connect } from 'react-redux';


class AdminMenu extends Component {

    render() {
        return (
            <div className="Outer-nav">
                <Nav>
                    <NavItem>
                        <NavLink tag={RouterNavLink} activeClassName="active" exact to="/admin">Synköversikt</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={RouterNavLink} activeClassName="active" exact to="/admin/customerresponsibleauthority">Kund - Huvudman</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => this.props.signOutAdmin()}>Logga ut</NavLink>
                    </NavItem>
                </Nav>
            </div>
        );
    }
}

const mapStatetoProps = state => ({

})

export default connect(mapStatetoProps, { signOutAdmin })(AdminMenu);

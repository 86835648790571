import React, { Component } from 'react';
import AdminErrorInTable from './AdminErrorInTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class AdminTableComponent extends Component {
    state = {
        showErrors: false
    }

    toggleErrors = (id, hasErrors) => {
        hasErrors && this.setState({
            showErrors: {
                ...this.state.showErrors,
                [id]: !this.state.showErrors[id]
            }
        })
    }

    render() {
        const { showErrors } = this.state;
        const { start, stop, environment, statistics, isExport } = this.props;

        let environmentValue = environment === 0 ? 'Testmiljö' : 'Produktionsmiljö';
        let startTime = start ? new Date(start).toLocaleString() : ''
        let stopTime = stop ? new Date(stop).toLocaleString() : 'Körs just nu'

        return (
            <table className="admin_table">
                <thead>
                    <tr>
                        {isExport && <th>Miljö</th>}
                        <th></th>
                        <th>Typ</th>
                        <th>Skapade</th>
                        <th>Uppdaterade</th>
                        {isExport && <th>Borttagna</th>}
                        {!isExport && <th>Inaktiverade</th>}
                        <th>Antal fel</th>
                        <th>Starttid</th>
                        <th>Sluttid</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {isExport && <td colSpan='7'>{environmentValue}</td>}
                        {!isExport && <td colSpan='6'></td>}
                        <td>{startTime}</td>
                        <td>{stopTime}</td>
                    </tr>
                    {statistics.map(s => {
                        let startTime = s.start ? new Date(s.start).toLocaleTimeString('sv-SE') : '';
                        let stopTime = s.stop ? new Date(s.stop).toLocaleTimeString('sv-SE') : '';
                        let hasErrors = s.errors > 0 ? true : false;
                        let errorIcon = (hasErrors && !showErrors[s.id]) ? <FontAwesomeIcon icon="caret-right" className="admin_caret_icon" /> :
                            (hasErrors && showErrors[s.id]) ? <FontAwesomeIcon icon="caret-down" className="admin_caret_icon" /> : '';
                        let backgroundTr = hasErrors && showErrors[s.id] ? 'choosen_error_tr' :
                            hasErrors ? 'hover_error_tr' : '';

                        return (<React.Fragment key={s.id}>
                            <tr className={backgroundTr} onClick={() => this.toggleErrors(s.id, hasErrors)}>
                                {isExport && <td></td>}
                                <td className="td-right">{errorIcon}</td>
                                <td>{s.type}</td>
                                <td className="td-middle">{s.created}</td>
                                <td className="td-middle">{s.updated}</td>
                                {isExport && <td className="td-middle">{s.deleted}</td>}
                                {!isExport && <td className="td-middle">{s.inactivated}</td>}
                                <td className="td-middle">{s.errors}</td>
                                <td>{startTime}</td>
                                <td>{stopTime}</td>
                            </tr>
                            {showErrors[s.id] &&
                                <AdminErrorInTable
                                    id={s.id}
                                    isExport={isExport}
                                />
                            }
                        </React.Fragment>)
                    }
                    )}
                </tbody>
            </table>
        )
    }
}

export default AdminTableComponent;

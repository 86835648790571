import React, { Component } from 'react';
import '../styles/Register.css';
import { connect } from 'react-redux';
import { TopBar } from './TopBar';

class ManagingSystemMismatch extends Component {
    getManagingSystemName = () => {
        const { managingSystem } = this.props;

        switch (managingSystem) {
            case "DataSync":
                return "Eventful SkolSynk";
            default:
                return managingSystem;
        }
    }

    render() {
        return (
            <div className="register">
                <TopBar />
                <div className="register_inner">
                    <h1>Hanterande System Matchar Inte</h1>
                    <p>Synken för denna kund hanteras av {this.getManagingSystemName()} och kan därför inte hanteras via detta gränssnitt.</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    managingSystem: state.auth.managingSystem
});

export default connect(mapStateToProps)(ManagingSystemMismatch);

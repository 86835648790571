import React, { Component } from 'react';
import { AdminGetCustomers } from '../../store/Admin/AdminCustomerAction';
import { connect } from 'react-redux';
import Loading from '../Loading';
import { Link } from 'react-router-dom';
import '../../styles/Admin/Admin.css';
import Download from '../Download';


class Admin extends Component {

    componentDidMount() {
        this.props.AdminGetCustomers(this.props.adminToken); 
    }

    renderImport = (imp) => {
        if (!imp) return; 

        let importUrl = '/admin/import/' + imp.id;
        let importStartTime = imp.importStart ? new Date(imp.importStart).toLocaleString() : '';
        let importEndTime = imp.importStop ? new Date(imp.importStop).toLocaleString() : 'Körs just nu';

        return (
            <tr>
                <td></td>
                <td colSpan={3}><Link to={importUrl}>Import</Link></td>
                <td className="td-middle">{imp.errorCount}</td>
                <td>{importStartTime}</td>
                <td>{importEndTime}</td>
            </tr>
        );
    }

    render() {
        const { customers } = this.props;

        return (
            <div>
                <h1>Synköversikt</h1>
                {!customers && <Loading />}
                {customers &&
                    <table className="admin_table m20">
                        <thead>
                            <tr>
                                <th>Kund</th>
                                <th colSpan={2}>Tjänst</th>
                                <th>Miljö</th>
                                <th>Antal fel</th>
                                <th>Starttid</th>
                                <th>Sluttid</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customers.map(c => {
                                return (
                                    <React.Fragment key={c.id}>
                                        <tr>
                                            <td colSpan={7} className="admin_customer_name">{c.displayName}</td>
                                        </tr>
                                        { this.renderImport(c.import) }
                                        {c.recipientEnvironmentsExports.map(cre => {
                                            let environment = cre.environment === 0 ? 'Testmiljö' : 'Produktionsmiljö';
                                            let startTime = cre.exportStart ? new Date(cre.exportStart).toLocaleString() : '';
                                            let endTime = cre.exportStop ? new Date(cre.exportStop).toLocaleString() : 'Körs just nu';
                                            let url = '/admin/recipientExport/' + cre.id;
                                            return (
                                                <tr key={cre.id}>
                                                    <td></td>
                                                    <td><Link to={url}>{cre.displayName}</Link></td>
                                                    <td><Download href={`/api/admin/customer/${c.id}/recipientEnvironment/${cre.recipientEnvironmentId}/excel/`} token={this.props.adminToken} adminGui='adminGui' /></td>
                                                    <td>{environment}</td>
                                                    <td className="td-middle">{cre.errorCount}</td>
                                                    <td>{startTime}</td>
                                                    <td>{endTime}</td>
                                                </tr>
                                            )
                                        })}
                                    </React.Fragment>
                                )
                            }
                            )}
                        </tbody>
                    </table>
                }
            </div>
        )
    }
}


const mapStatetoProps = state => ({
    adminToken: state.adminAuth.adminToken,
    customers: state.admin.customers
});

export default connect(mapStatetoProps, { AdminGetCustomers })(Admin);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AdminGetExportErrors, AdminGetImportErrors } from '../../store/Admin/AdminCustomerAction';
import Loading from '../Loading';
import Modal from './Modal';
import { severity, requestDataFunc, message } from './AdminHelperMessage';


class AdminErrorInTable extends Component {
    state = {
        showErrorMsg: false
    }

    componentDidMount() {
        const adminToken = this.props.adminToken;
        const id = this.props.id;
        const isExport = this.props.isExport;
        if (isExport) {
            this.props.AdminGetExportErrors(adminToken, id)
        } else {
            this.props.AdminGetImportErrors(adminToken, id)
        }
    }

    showErrorModal = (id) => {
        this.setState({
            showErrorMsg: {
                [id]: !this.state.showErrorMsg[id]
            }
        })
    }

    closeErrorModal = () => {
        this.setState({ showErrorMsg: false })
    }

    render() {
        const { exportErrors, importErrors, isExport, id } = this.props;
        const { showErrorMsg } = this.state;

        const errors = isExport ? exportErrors[id] : importErrors[id];

        return (
            <React.Fragment>
                {!errors && <tr><td><Loading /></td></tr>}
                {errors && <React.Fragment>
                    <tr className="admin_error_header">
                        <td>Severity</td>
                        <td colSpan={8}>Meddelande</td>
                    </tr>
                    {errors.map(err => {
                        const timeStamp = err.timeStamp || err.timestamp;
                        let timeStampPretify = timeStamp ? new Date(timeStamp).toLocaleString() : '';

                        return (<React.Fragment key={err.id}>
                            <tr className="hover_error_tr" onClick={() => this.showErrorModal(err.id)}>
                                <td>{severity(err.severity)}</td>
                                <td colSpan={8} className="admin_error_msg" title={err.message}>{err.message}</td>
                            </tr>
                            {showErrorMsg[err.id] &&
                                <tr className="tr_error_modal"><td>
                                    <Modal close={() => this.closeErrorModal()} title="Felmeddelande">
                                        <div>
                                            <pre><b>Id: </b>{err.id}</pre>
                                            {err.objectId && <pre><b>ObjectId: </b>{err.objectId}</pre>}
                                            <pre><b>TimeStamp: </b>{timeStampPretify}</pre>
                                            <pre><b>Severity: </b>{severity(err.severity)}</pre>
                                            {err.stackTrace && <pre><b>StackTrace: </b>{err.stackTrace}</pre>}
                                            {err.requestData && <pre className="break_word"><b>RequestData: </b>{requestDataFunc(err.requestData)}</pre>}
                                            <pre className="break_word"><b>Message: </b>{message(err.message)}</pre>
                                        </div>
                                    </Modal>
                                </td></tr>}
                        </React.Fragment>)
                    }
                    )}
                </React.Fragment>}
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => ({
    adminToken: state.adminAuth.adminToken,
    exportErrors: state.admin.exportErrors,
    importErrors: state.admin.importErrors
})

export default connect(mapStatetoProps, { AdminGetExportErrors, AdminGetImportErrors })(AdminErrorInTable);

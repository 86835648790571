import axios from 'axios';
const ADMIN_CUSTOMER_RESPONSIBLE_AUTHORITY = 'ADMIN_CUSTOMER_RESPONSIBLE_AUTHORITY';
const ADMIN_RESPONSIBLE_AUTHORITIES = 'ADMIN_RESPONSIBLE_AUTHORITIES';


export const AdminGetCustomerResposibleAuthority = (token) => dispatch => {

    const url = '/api/admin/customerResponsibleAuthority'
    axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(res =>
            dispatch({
                type: ADMIN_CUSTOMER_RESPONSIBLE_AUTHORITY,
                customerResponsibleAuthority: res.data,
            })
        );
};

export const AdminDeleteCustomerResposibleAuthority = (token, customerId, responsibleAuthorityId) => dispatch => {
    const url = '/api/admin/customerResponsibleAuthority?customerId=' + customerId + '&responsibleAuthorityId=' + responsibleAuthorityId;
    return axios.delete(url, { headers: { Authorization: `Bearer ${token}` } });
}

export const AdminAddCustomerResposibleAuthority = (token, customerId, responsibleAuthorityId) => dispatch => {
    const url = '/api/admin/customerResponsibleAuthority';
    return axios.post(url, { customerId: customerId, responsibleAuthorityId: responsibleAuthorityId }, { headers: { Authorization: `Bearer ${token}` }, });
}

export const AdminGetResposibleAuthorities = (search, token) => dispatch => {

    const url = '/api/admin/responsibleAuthority?search=' + search;
    axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(res =>
            dispatch({
                type: ADMIN_RESPONSIBLE_AUTHORITIES,
                responsibleAuthorities: res.data,
            })
        );
};


const initialState = {
    customerResponsibleAuthority: undefined,
    responsibleAuthorities: undefined
}


export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case ADMIN_CUSTOMER_RESPONSIBLE_AUTHORITY:
            return {
                ...state,
                customerResponsibleAuthority: action.customerResponsibleAuthority,
            };
        case ADMIN_RESPONSIBLE_AUTHORITIES:
            return {
                ...state,
                responsibleAuthorities: action.responsibleAuthorities,
            };
        default:
            return state;
    }

};
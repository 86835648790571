import React, { Component } from 'react';
import Loading from '../Loading';
import { connect } from 'react-redux';
import AdminLogIn from './AdminLogIn';
import { adminSilentAuth } from '../../store/Admin/AdminAuthAction';


class PrivateAdmin extends Component {

    componentDidMount() {
        this.props.adminSilentAuth();
    };

    render() {
        const { needLogin, adminToken } = this.props;
        const ChildComponent = this.props.component;

        if (needLogin) {
            return <AdminLogIn />;
        } else if (!adminToken) {
            return <Loading classNameBg='spinner-large_bg' classNameInner='spinner-large' />;
        } else {
            return <ChildComponent />;
        }
    }
}

const mapStatetoProps = state => ({
    adminToken: state.adminAuth.adminToken,
    needLogin: state.adminAuth.needLogin
})

export default connect(mapStatetoProps, { adminSilentAuth })(PrivateAdmin);
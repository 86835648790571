import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../Loading';
import { AdminGetResposibleAuthorities, AdminAddCustomerResposibleAuthority } from '../../store/Admin/AdminResponsibleAuthority';
import debounce from 'lodash/debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from './Modal';


class AdminAddCustomerRespAuthModal extends Component {
    state = {
        search: '',
        toggleSchools: false,
        saving: false,
        saved: false
    }

    componentDidMount() {
        this.update();
    }

    update = () => {
        this.props.AdminGetResposibleAuthorities(this.state.search, this.props.adminToken);
    }

    search = (e) => {
        this.setState({
            search: e.target.value
        }, this.searchDebounce());
    }
    searchDebounce = debounce(() => {
        this.update();
    }, 500)

    toggleSchoolUnits = (id) => {
        this.setState({
            toggleSchools: {
                ...this.state.toggleSchools,
                [id]: !this.state.toggleSchools[id]
            }
        })
    }
    addResponsibleAuthority = (responsibleAuthorityId) => {
        const { customerId, adminToken } = this.props;
        this.setState({
            saving: {
                ...this.state.saving,
                [responsibleAuthorityId]: !this.state.saving[responsibleAuthorityId]
            },
            saved: false
        })
        this.props.AdminAddCustomerResposibleAuthority(adminToken, customerId, responsibleAuthorityId)
            .then(() => {
                this.setState({
                    saving: false,
                    saved: {
                        ...this.state.saved,
                        [responsibleAuthorityId]: !this.state.saved[responsibleAuthorityId]
                    }
                })
                this.props.close(true)
            })
    }

    render() {
        let { responsibleAuthorities, displayName, close, customerResponsibleAuthorities } = this.props;
        const { search, toggleSchools, saving, saved } = this.state;

        return (
            <Modal close={close} title={`Välj en huvudman nedan för att koppla till ${displayName}`}>
                <div>
                    <input value={search} type="text" className="search--resp_auth" placeholder="Sök efter en huvudman" onChange={this.search} />
                    <table>
                        <thead>
                            <tr>
                                <th>Huvudman</th>
                                <th></th>
                                <th>Domäner</th>
                                <th>Typ</th>
                                <th>OrgNr</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!responsibleAuthorities && <tr><td><Loading /></td></tr>}
                            {responsibleAuthorities && <React.Fragment>
                                {responsibleAuthorities
                                    .filter(r => !customerResponsibleAuthorities.includes(r.id)) 
                                    .map(r => {
                                    let domains = [r.emailTopLevelDomainName, r.responsibleAuthorityEmailTopLevelDomainName, r.websiteTopLevelDomainName]
                                        .filter(d => d !== null)
                                        .filter((value, index, self) => self.indexOf(value) === index) //distinct
                                        .join(', ');
                                    let addResponsibleAuthority =
                                        <span onClick={() => this.addResponsibleAuthority(r.id)} className="add-resposible-authority">
                                            <FontAwesomeIcon icon="plus-circle" className="add-resposible-authority-icon" /> Lägg till
                                    </span>;
                                    return (<React.Fragment key={r.id}>
                                        <tr>
                                            <td>{r.title} <span className="small_red" onClick={() => this.toggleSchoolUnits(r.id)}>{toggleSchools[r.id] ? 'Dölj ' : 'Visa '} skolor</span></td>
                                            <td className="td_saving" title="Koppla huvudman till {displayName}">{addResponsibleAuthority}
                                                {saving[r.id] && <Loading />}
                                                {saved[r.id] && <FontAwesomeIcon icon="check" className="add-resposible-authority-icon" />}
                                            </td>
                                            <td>{domains}</td>
                                            <td>{r.type}</td>
                                            <td>{r.peOrgNr}</td>
                                        </tr>
                                        {toggleSchools[r.id] && <React.Fragment>
                                            {r.schoolUnits.map((rsu, i) =>
                                                <tr key={i}>
                                                    <td className="pl-40">{rsu.title}</td>
                                                    <td colSpan={4}>{rsu.schoolUnitCode}</td>
                                                </tr>)
                                            }
                                        </React.Fragment>}
                                    </React.Fragment>)
                                }
                                )}
                            </React.Fragment>}
                        </tbody>
                    </table>
                </div>
            </Modal>
        )
    }

}

const mapStatetoProps = state => ({
    adminToken: state.adminAuth.adminToken,
    responsibleAuthorities: state.responsibleAuthority.responsibleAuthorities
});

export default connect(mapStatetoProps, {
    AdminGetResposibleAuthorities,
    AdminAddCustomerResposibleAuthority
})(AdminAddCustomerRespAuthModal);


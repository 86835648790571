import axios from 'axios';
import * as FetchStatus from '../util/FetchStatus'

const GET_RECIPIENTS = 'GET_RECIPIENTS';
const GET_RECIPIENT = 'GET_RECIPIENT';
const POST_RECIPIENT = 'POST_RECIPIENT';
const COMPLETE_POST_RECIPIENT = 'COMPLETE_POST_RECIPIENT';
const RESET_SYNC_PENDING = 'RESET_SYNC_PENDING';
const RESET_SYNC_COMPLETE = 'RESET_SYNC_COMPLETE';
const RESET_SYNC_ERROR = 'RESET_SYNC_ERROR';

export const GetRecipients = (token) => dispatch => {

    const url = '/api/recipient'
    axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(res =>
            dispatch({
                type: GET_RECIPIENTS,
                recipients: res.data
            })
        )
}

export const GetRecipient = (token, id) => dispatch => {
    dispatch({
        type: GET_RECIPIENT,
        fetchingRecipient: true
    })

    const url = '/api/recipient/' + id;
    return axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(res => {
            dispatch({
                type: GET_RECIPIENT,
                recipient: res.data,
                fetchingRecipient: false
            })

            return res.data
        });
}

export const PostRecipient = (token, recipient) => async dispatch => {
    dispatch({
        type: POST_RECIPIENT,
        posting: true
    })

    const url = '/api/recipient';
    try {
        const res = await axios.post(url, recipient, { headers: { Authorization: `Bearer ${token}` } })

        dispatch({
            type: COMPLETE_POST_RECIPIENT,
            recipient: res.data,
            posting: false,
            couldNotSave: false
        })
    } catch (err) {
        console.error(err)
        dispatch({
            type: POST_RECIPIENT,
            posting: false,
            couldNotSave: true
        })
    }
}

export const ResetSync = (token, recipientId) => async dispatch => {
    dispatch({ type: RESET_SYNC_PENDING });
    const url = '/api/recipient/' + recipientId + '/reset';
    try {
        await axios.post(url, null, { headers: { Authorization: `Bearer ${token}` } });
        dispatch({ type: RESET_SYNC_COMPLETE });
    } catch (error) {
        console.error(error);
        dispatch({ type: RESET_SYNC_ERROR, error })
    }
}

const initialState = {
    recipients: [],
    recipient: [],
    fetchingRecipient: true,
    posting: false,
    couldNotSave: false
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case GET_RECIPIENTS:
            return {
                ...state,
                recipients: action.recipients
            }
        case GET_RECIPIENT:
            return {
                ...state,
                recipient: action.recipient,
                fetchingRecipient: action.fetchingRecipient
            }
        case COMPLETE_POST_RECIPIENT:
            return {
                ...state,
                recipient: action.recipient,
                posting: action.posting,
                couldNotSave: action.couldNotSave
            }
        case POST_RECIPIENT:
            return {
                ...state,
                posting: action.posting,
                couldNotSave: action.couldNotSave
            }
        case RESET_SYNC_PENDING:
            return {
                ...state,
                resetStatus: FetchStatus.PENDING,
                resetError: null
            };
        case RESET_SYNC_COMPLETE:
            return {
                ...state,
                resetStatus: FetchStatus.SUCCESS,
                resetError: null
            }
        case RESET_SYNC_ERROR:
            return {
                ...state,
                resetStatus: FetchStatus.FAILURE,
                resetError: action.error
            }
        default:
            return state;
    }
}
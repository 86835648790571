import React, { Component } from 'react';
import { AdminGetRecipientExport } from '../../store/Admin/AdminCustomerAction';
import { connect } from 'react-redux';
import Loading from '../Loading';
import AdminTableComponent from './AdminTableComponent';
import { Link } from 'react-router-dom';


class AdminExport extends Component {

    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.AdminGetRecipientExport(this.props.adminToken, id)
    }

    render() {
        const { exports } = this.props;

        if (!exports) {
            return (<Loading />)
        } else {
            return (
                <div>
                    <p><Link to={'/admin'}>Tillbaka till admin</Link></p>

                    <div className="admin_customer_info">
                        <p><span>Kund</span><span>{exports.customerName}</span></p>
                        <p><span>Tjänst</span><span>{exports.recipientDisplayName}</span></p>
                    </div>

                    <p className="latest">Senaste exporten</p>

                    <AdminTableComponent
                        isExport={true}
                        environment={exports.environment}
                        start={exports.start}
                        stop={exports.stop}
                        statistics={exports.statistics}
                    />
                </div>
            )
        }
    }
}


const mapStatetoProps = state => ({
    adminToken: state.adminAuth.adminToken,
    exports: state.admin.export
});

export default connect(mapStatetoProps, { AdminGetRecipientExport })(AdminExport);

import MsalApi from '../../services/MsalApi';

const UPDATE_AUTH_STATUS = 'ADMIN_UPDATE_AUTH_STATUS';
const LOGOUT = 'ADMIN_LOGOUT';

export const adminAuth = () => async dispatch => {
    const tokenResponse = await MsalApi.idTokenPopup(MsalApi.ADMIN_CLIENT_ID);
    dispatch({
        type: UPDATE_AUTH_STATUS,
        adminToken: tokenResponse.idToken.rawIdToken,
        needLogin: false
    })
};

export const adminSilentAuth = () => async dispatch => {
    let tokenResponse;
    try {
        tokenResponse = await MsalApi.idTokenSilent(MsalApi.ADMIN_CLIENT_ID);
    } catch (err) {
        console.error(err)
        return dispatch({
            type: UPDATE_AUTH_STATUS,
            needLogin: true
        });
    }

    dispatch({
        type: UPDATE_AUTH_STATUS,
        adminToken: tokenResponse.idToken.rawIdToken,
        needLogin: false
    })
};

export const signOutAdmin = () => async dispatch => {
    await MsalApi.logout(MsalApi.ADMIN_CLIENT_ID);

    return dispatch({
        type: LOGOUT
    });
};


const initialState = {
    needLogin: false,
    adminToken: undefined
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case UPDATE_AUTH_STATUS:
            return {
                ...state,
                needLogin: action.needLogin,
                adminToken: action.adminToken
            };
        case LOGOUT:
            return {
                ...state,
                adminToken: undefined,
                needLogin: true
            };
        default:
            return state;
    }

};


import React, { Component } from 'react';
import AdminMenu from './AdminMenu';
import { TopBar } from '../TopBar';

class AdminLayout extends Component {

    render() {
        return (
            <div className="layout">
                <TopBar />
                <div className="inner_layout">
                    <AdminMenu />
                </div>
                <div className="children">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
export default AdminLayout;

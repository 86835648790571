import React, { Component } from 'react';
import { Button } from 'reactstrap';
import '../styles/NavMenu.css';
import { SyncMode } from './SyncMode';
import { connect } from 'react-redux';
import { GetRecipient, PostRecipient, ResetSync } from '../store/RecipientAction';
import { GetSchoolUnits } from '../store/SchoolUnitAction';
import { GetScbCode } from '../store/ScbAction';
import Loading from './Loading';
import SchoolUnits from './SchoolUnits';
import SavingFeedback from './SavingFeedback';
import { Prompt } from 'react-router'
import * as FetchStatus from '../util/FetchStatus';

const RESET_TEXT_DISPLAY_TIMEOUT = 10000;

class Synk extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resetPerformedAt: 0,
            showPopup: false,
            hasConfigurationChanged: false,
            hasSchoolUnitsChanged: false,
            recipientConfiguration: undefined,
            recipientSchoolUnits: []
        };
    }

    componentDidMount() {
        const token = this.props.token;
        const id = this.props.match.params.id;

        const recipientPromise = this.props.GetRecipient(token, id);
        const schoolUnitsPromise = this.props.GetSchoolUnits(token);
        const scbCodePromise = this.props.GetScbCode(token);

        Promise.all([
            recipientPromise,
            schoolUnitsPromise,
            scbCodePromise
        ]).then((results) => {
            const recipient = results[0];

            this.setState({
                recipientConfiguration: recipient.configuration,
                recipientSchoolUnits: recipient.schoolUnits
            });
        });
    }

    save = () => {
        const recipient = { ...this.props.recipient };
        recipient.configuration = this.state.recipientConfiguration;
        recipient.schoolUnits = this.state.recipientSchoolUnits;

        this.setState({ showPopup: true });
        this.props.PostRecipient(this.props.token, recipient).then(() => {
            if (this.props.successfullySaved) {
                this.setState({
                    hasConfigurationChanged: false,
                    hasSchoolUnitsChanged: false,
                    recipientConfiguration: this.props.recipient.configuration,
                    recipientSchoolUnits: this.props.recipient.schoolUnits
                })
            }
        });
    }

    resetSync = () => {
        if (window.confirm('Är du säker på att all aktiv data ska sykroniseras igen vid nästa export?')) {
            this.props.ResetSync(this.props.token, this.props.recipient.id);
            this.setState({ resetPerformedAt: this.getTimestampNow() })
            setTimeout(() => {
                const timeSinceReset = this.getTimestampNow() - this.state.resetPerformedAt
                const timeoutWithMargin = RESET_TEXT_DISPLAY_TIMEOUT - 50
                if (timeSinceReset >= timeoutWithMargin) {
                    this.setState({ resetPerformedAt: 0 })
                }
            }, RESET_TEXT_DISPLAY_TIMEOUT)
        }
    }

    hasEqualSchoolUnitCode = (first, second) => {
        if (!first.schoolUnitCode && !second.schoolUnitCode) {
            return true;
        } else {
            return first.schoolUnitCode === second.schoolUnitCode;
        }
    }

    hasSchoolUnitChanged = (recipientSchoolUnit) => {
        const orig = this.props.recipient.schoolUnits.find(rsu => rsu.schoolUnitId === recipientSchoolUnit.schoolUnitId);

        if (orig) {
            return orig.active !== recipientSchoolUnit.active || !this.hasEqualSchoolUnitCode(orig, recipientSchoolUnit);
        } else {
            return false;
        }
    }

    onChangeSchoolUnits = (recipientSchoolUnits) => {
        let hasChanged = false;

        if (this.props.recipient.schoolUnits.length === recipientSchoolUnits.length) {
            hasChanged = recipientSchoolUnits.some(rsu => this.hasSchoolUnitChanged(rsu));
        } else {
            hasChanged = true;
        }

        this.setState({
            recipientSchoolUnits: recipientSchoolUnits,
            hasSchoolUnitsChanged: hasChanged,
            showPopup: false
        })
    }

    onChangeConfiguration = (recipientConfiguration) => {
        const orig = this.props.recipient.configuration;
        let hasChanged = false;

        if (!orig || orig.active !== recipientConfiguration.active || orig.environment !== recipientConfiguration.environment) {
            hasChanged = true;
        }

        this.setState({
            recipientConfiguration: recipientConfiguration,
            hasConfigurationChanged: hasChanged,
            showPopup: false
        })
    }

    isSchoolUnitValid = (recipientSchoolUnit) => {
        const { scbSchoolUnits } = this.props;

        if (recipientSchoolUnit.schoolUnitCode) {
            return scbSchoolUnits.some(su => su.schoolUnitCode === recipientSchoolUnit.schoolUnitCode);
        } else {
            return !recipientSchoolUnit.active;
        }
    }

    getTimestampNow = () => {
        const date = new Date();
        return date.getTime();
    }

    canSave = () => {
        const { recipientSchoolUnits, hasConfigurationChanged, hasSchoolUnitsChanged } = this.state;
        let hasInvalidSchoolUnits = false;

        if (recipientSchoolUnits) {
            hasInvalidSchoolUnits = recipientSchoolUnits.some(rsu => !this.isSchoolUnitValid(rsu));
        }

        if (hasInvalidSchoolUnits) {
            return false;
        } else {
            return hasConfigurationChanged || hasSchoolUnitsChanged;
        }
    }

    renderSyncConfiguration = () => {
        const { recipient, schoolUnits, loading, token, scbSchoolUnits } = this.props;
        const { showPopup } = this.state;
        const resetPending = this.props.resetStatus === FetchStatus.PENDING;

        if (loading) {
            return <Loading />
        } else {
            return <React.Fragment>
                <h1>{recipient.displayName} Synk</h1>
                <SyncMode
                    recipientConfiguration={recipient.configuration || {}}
                    onChange={this.onChangeConfiguration}
                    loading={loading}
                    recipientId={recipient.id}
                    token={token}
                />
                <div>
                    <Button className="btn_sync_small" disabled={resetPending} onClick={this.resetSync}>Synkronisera om all data</Button>
                    {this.renderResetMessage()}
                </div>
                <hr />
                <SchoolUnits
                    loading={loading}
                    schoolUnits={schoolUnits}
                    recipientSchoolUnits={recipient.schoolUnits}
                    onChange={this.onChangeSchoolUnits}
                    scbSchoolUnits={scbSchoolUnits}
                />
                <div className="sync_btn_group">
                    <Button className="btn_sync" disabled={!this.canSave()} onClick={this.save}>Genomför ändringar</Button>
                    {showPopup && <SavingFeedback />}
                </div>
            </React.Fragment>
        }
    }

    renderResetMessage() {
        if (this.state.resetPerformedAt === 0) {
            return null;
        }

        switch (this.props.resetStatus) {
            case FetchStatus.PENDING:
                return <span>Genomför återställning...</span>
            case FetchStatus.FAILURE:
                return <span className="error_text">{`Det gick inte att återställa synkroniseringen: ${this.props.resetError.message}.`}</span>
            case FetchStatus.SUCCESS:
                return <span>All aktiv data kommer synkroniseras om i nästa export.</span>
            default:
                return null;
        }
    }

    render() {
        const { hasConfigurationChanged, hasSchoolUnitsChanged } = this.state;

        return (
            <div>
                <Prompt when={(hasConfigurationChanged || hasSchoolUnitsChanged)} message="Det finns osparade ändringar. Vill du lämna sidan?" />
                {this.renderSyncConfiguration()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    recipient: state.recipient.recipient,
    loading: state.recipient.fetchingRecipient || state.schoolUnit.fetching || state.scb.fetching,
    schoolUnits: state.schoolUnit.schoolUnits,
    successfullySaved: !state.recipient.posting && !state.recipient.couldNotSave,
    scbSchoolUnits: state.scb.scbCodes,
    resetStatus: state.recipient.resetStatus,
    resetError: state.recipient.resetError
});

export default connect(mapStateToProps, { GetRecipient, GetSchoolUnits, PostRecipient, GetScbCode, ResetSync })(Synk);
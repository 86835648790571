import React, { Component } from 'react';
import { NavItem, NavLink, Nav } from 'reactstrap';
import '../styles/NavMenu.css';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { signOut } from '../store/AuthAction';
import { connect } from 'react-redux';
import { GetRecipients } from '../store/RecipientAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class NavMenu extends Component {

    componentDidMount() {
        this.props.GetRecipients(this.props.token);
    }

    render() {
        const { recipients } = this.props;
        
        return (
            <div className="Outer-nav">
                <Nav>
                    <NavItem>
                        <NavLink tag={RouterNavLink} activeClassName="active" exact to="/">Startsida</NavLink>
                    </NavItem>
                    {
                        recipients.map(recipient => {
                            let url = '/synk/' + recipient.id
                            let active = recipient.configuration ? recipient.configuration.active : false;
                            const open = window.location.pathname.includes(recipient.id); 
                            const symbols = open ? <FontAwesomeIcon icon="chevron-down" className="arrow" /> : <FontAwesomeIcon icon="chevron-right" className="arrow" />;
                            return (
                                <React.Fragment key={recipient.id}>
                                    <NavItem>
                                        <NavLink tag={RouterNavLink} activeClassName="active" to={url}>{recipient.displayName}<span className="open">{active && symbols}</span></NavLink>
                                    </NavItem>
                                    {active && open &&
                                        <NavItem className="report">
                                            <NavLink tag={RouterNavLink} activeClassName="active" to={/report/ + recipient.id}>{recipient.displayName} Rapport</NavLink>
                                        </NavItem>
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                    <NavItem>
                        <NavLink onClick={() => this.props.signOut()}>Logga ut</NavLink>
                    </NavItem>
                </Nav>
            </div>
        );
    }
}


const mapStatetoProps = state => ({
    token: state.auth.token,
    recipients: state.recipient.recipients
})

export default connect(mapStatetoProps, { signOut, GetRecipients })(NavMenu);

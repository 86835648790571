import React from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import Synk from './components/Synk';
import Report from './components/Report';
import Private from './components/Private';
import NotFound from './components/NotFound';
import './custom.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import Admin from './components/Admin/Admin';
import AdminExport from './components/Admin/AdminExport';
import AdminImport from './components/Admin/AdminImport';
import AdminLayout from './components/Admin/AdminLayout';
import PrivateAdmin from './components/Admin/PrivateAdmin';
import AdminCustomerResponsibleAuthority from './components/Admin/AdminCustomerResponsibleAuthority';


import { faFileDownload, faChevronRight, faChevronDown, faCaretDown, faCaretRight, faPlusCircle, faTimes, faTrash, faCheck } from '@fortawesome/free-solid-svg-icons'
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';
library.add(faFileDownload, faMicrosoft, faChevronDown, faChevronRight, faCaretDown, faCaretRight, faTimes, faPlusCircle, faTrash, faCheck);

const PrivateLayout = (Component) => {
    return (props) => <Private component={() => <Layout><Component {...props} /></Layout>} />
}

const PrivateAdminLayout = (Component) => {
    return (props) => <PrivateAdmin component={() => <AdminLayout><Component {...props} /></AdminLayout>} />
}

export default () => (
    <Switch>
        <Route exact path='/' component={PrivateLayout(Home)} />
        <Route exact path='/synk' component={PrivateLayout(Synk)} />
        <Route path='/synk/:id' component={PrivateLayout(Synk)} />
        <Route path='/report/:id' component={PrivateLayout(Report)} />
        <Route exact path='/admin' component={PrivateAdminLayout(Admin)} />
        <Route path='/admin/recipientExport/:id' component={PrivateAdminLayout(AdminExport)} />
        <Route path='/admin/import/:id' component={PrivateAdminLayout(AdminImport)} />
        <Route path='/admin/customerresponsibleauthority' component={PrivateAdminLayout(AdminCustomerResponsibleAuthority)} />
        <Route component={NotFound} />
    </Switch>
);


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { adminAuth } from '../../store/Admin/AdminAuthAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../styles/LogIn.css';


class AdminLogIn extends Component {

    logInLink = () => {
        this.props.adminAuth();
    }

    render() {
        return (
            <div className="login">
                <div className="login_box left">
                    <div>
                        <img className="logo" src="../Images/logo_neg.png" alt=""/>
                        <h1>Skolsynk för Microsoft (Admin)</h1>
                        <p>Hantera admin.</p>
                    </div>
                </div>
                <div className="login_box right">
                    <div>
                        <h2>Logga in</h2>
                        <div className="flex">
                            <button className="login__provider--microsoft login_btn" onClick={() => this.props.adminAuth()}>
                                <FontAwesomeIcon className="login__provider-icon" icon={['fab', 'microsoft']} /> Logga in
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    
});

export default withRouter(connect(mapStateToProps, { adminAuth })(AdminLogIn));

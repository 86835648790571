import React, { Component } from 'react';
import '../styles/SyncMode.css';
import { ToogleSwitch } from './ToggleSwitch';
import Loading from './Loading';
import Download from './Download';


export class SyncMode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: props.recipientConfiguration.active,
            environment: props.recipientConfiguration.environment ? props.recipientConfiguration.environment === 1 : false
        }
    }

    onChange = (active, environment) => {
        const recipientConfiguration = {
            active,
            environment: environment ? 1 : 0
        };

        this.setState({
            active: active,
            environment: environment
        })

        this.props.onChange(recipientConfiguration);
    }

    renderConfiguration = () => {
        const { loading } = this.props;
        const { active, environment } = this.state;

        if (loading) {
            return <Loading />
        } else {
            return <div className="col-6">
                <h2>Synk-läge</h2>
                <ToogleSwitch
                    name="active"
                    id="active"
                    value={active}
                    yes="På"
                    no="Av"
                    onChange={(checked) => this.onChange(checked, environment)}
                />
                <ToogleSwitch
                    name="production"
                    id="environment"
                    value={environment}
                    yes="Drift"
                    no="Test"
                    onChange={(checked) => this.onChange(active, checked)}
                />
            </div>
        }
    }

    render() {
        const { recipientId, token } = this.props;

        return (
            <div className="sync_mode flex m20">
                {this.renderConfiguration()}
                <div className="col-6">
                    <h2>Hämta sammanställning</h2>
                    <Download href={'/api/excel/' + recipientId} token={token}  />
                </div>
            </div>
        );
    }
}



export const severity = (severity) => {
    return (
        severity === 0 ? 'Fatal' :
            severity === 1 ? 'Error' :
                severity === 2 ? 'Warning' : ''
    );
};

export const requestDataFunc = (requestData) => {
    const requestDataRegex = /^(\{.*\})$/m;

    const match = requestData.match(requestDataRegex);
    if (match && match.length > 1) {
        var json = JSON.parse(match[1])
        var formattedJson = JSON.stringify(json, undefined, 2);
        return (
            requestData = requestData.replace(match[1], formattedJson)
        )
    }
}

export const message = (message) => {
    const messageRegex = /^Body: (\{.*\})$/m;

    const matchMsg = message.match(messageRegex);
    if (matchMsg && matchMsg.length > 1) {
        var jsonMsg = JSON.parse(matchMsg[1])
        var formattedJsonMsg = JSON.stringify(jsonMsg, undefined, 2);
        return (
            message = message.replace(matchMsg[1], formattedJsonMsg)
        )
    }
}

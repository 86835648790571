const ATTACHMENT_FILENAME_REGEX = /filename=([^;]*);/;

export const download = (url, configuration = {}) => {
    return new Promise((resolve, reject) => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";


        var x = new XMLHttpRequest();
        x.open("GET", url, true);

        if (configuration.headers) {
            Object.keys(configuration.headers)
                .forEach(key => {
                    x.setRequestHeader(key, configuration.headers[key]);
                });
        }

        x.responseType = 'blob';
        x.onload = function (e) {
            var blob = e.currentTarget.response;
            var filename = "";

            if (x.status !== 200) return reject('Server response was ' + x.status);

            var disposition = x.getResponseHeader('Content-Disposition');
            if (!disposition || disposition.indexOf('attachment') === -1) return reject('Response did not contain the Content-Disposition header.');

            const matches = ATTACHMENT_FILENAME_REGEX.exec(disposition);
            if (matches !== null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            } else {
                filename = configuration.defaultName ? configuration.defaultName : 'file';
            }

            if (window.navigator.msSaveOrOpenBlob) {
                // IE11
                window.navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                // Google chome, Firefox, ....
                var url = (window.URL || window.webkitURL).createObjectURL(blob);
                a.href = url;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(url);
            }

            resolve(filename);
        };

        x.send();
    });
};

import * as Msal from 'msal';


class MsalApi {
    static DEFAULT_CLIENT_ID = "a4319ac7-9b0f-4e2f-ae26-2f92c847916b";
    static ADMIN_CLIENT_ID = "fd3a72f3-b687-4b6a-9e08-51218bac43d9"

    static application(clientId) {
        var msalConfig = {
            auth: {
                clientId: clientId,
                authority: "https://login.microsoftonline.com/organizations",
                redirectUri: window.location.origin
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true
            }
        };
        var app = new Msal.UserAgentApplication(msalConfig);
        return app;
    }

    static idTokenSilent(clientId = MsalApi.DEFAULT_CLIENT_ID) {
        const application = MsalApi.application(clientId);

        var request = {
            scopes: [clientId]
        };

        return application.acquireTokenSilent(request);
    }

    static idTokenPopup(clientId = MsalApi.DEFAULT_CLIENT_ID) {
        const application = MsalApi.application(clientId);

        var request = {
            scopes: [clientId]
        };

        return application.loginPopup(request);
    }

    static logout(clientId = MsalApi.DEFAULT_CLIENT_ID) {
        const application = MsalApi.application(clientId);
        return application.logout();
    }
}

export default MsalApi;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../Loading';
import { AdminGetCustomerResposibleAuthority, AdminDeleteCustomerResposibleAuthority } from '../../store/Admin/AdminResponsibleAuthority';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminAddCustomerRespAuthModal from './AdminAddCustomerRespAuthModal';


class AdminCustomerResponsibleAuthority extends Component {
    state = {
        toggleCustomer: false,
        toggleSchools: false,
        saving: true,
        saved: false,
        showModal: false
    }

    componentDidMount() {
        this.upDateComponent();
    }

    upDateComponent = () => {
        this.props.AdminGetCustomerResposibleAuthority(this.props.adminToken);
    }

    addResponsibleAuthority = (id) => {
        this.setState({
            showModal: {
                ...this.state.showModal,
                [id]: !this.state.showModal[id]
            }
        })
    }

    closeModal = (update) => {
        this.setState({
            showModal: false
        })
        if (update) {
            this.upDateComponent();
        }
    }

    removeResponsibleAuthority = (cra, responsibleAuthorityId, responsibleAuthorityTitle) => {
        const { adminToken } = this.props;
        const confirm = window.confirm(`Är du säker på att du vill koppla bort ${responsibleAuthorityTitle} från ${cra.displayName}?`)
        if (confirm) {
            this.setState({
                saving: {
                    ...this.state.saving,
                    [responsibleAuthorityId]: !this.state.saving[responsibleAuthorityId]
                },
                saved: false
            })
            this.props.AdminDeleteCustomerResposibleAuthority(adminToken, cra.id, responsibleAuthorityId)
                .then(() => {
                    this.setState({
                        saving: false,
                        saved: {
                            ...this.state.saved,
                            [responsibleAuthorityId]: !this.state.saved[responsibleAuthorityId]
                        },
                    })

                    if (cra.responsibleAuthorities.length === 1) {
                        this.setState({
                            toggleCustomer: {
                                ...this.state.toggleCustomer,
                                [cra.id]: false
                            }

                        })
                    } 
                    this.upDateComponent(); 
                })
        }
    }

    toggleCustomerRow = (hasResponsibleAuthorities, id) => {
        (hasResponsibleAuthorities > 0) && this.setState({
            toggleCustomer: {
                ...this.state.toggleCustomer,
                [id]: !this.state.toggleCustomer[id]
            }
        })
    }

    toggleSchoolUnits = (id) => {
        this.setState({
            toggleSchools: {
                ...this.state.toggleSchools,
                [id]: !this.state.toggleSchools[id]
            }
        })
    }

    render() {
        const { customerResponsibleAuthority } = this.props;
        const { toggleCustomer, toggleSchools, saving, saved, showModal } = this.state;

        console.log(customerResponsibleAuthority)

        return (
            <div className="responsible_authority">
                <h1>Koppling kund-huvudman</h1>
                {!customerResponsibleAuthority && <Loading />}

                {customerResponsibleAuthority && <div>
                    {customerResponsibleAuthority.map(cra => {
                        let hasResponsibleAuthorities = cra.responsibleAuthorities.length;
                        let showRespAuthOrAdd = hasResponsibleAuthorities ? `Kopplad till ${hasResponsibleAuthorities} huvudmän` :
                            <span onClick={() => this.addResponsibleAuthority(cra.id)} className="add-resposible-authority">
                                <FontAwesomeIcon icon="plus-circle" className="add-resposible-authority-icon" /> Lägg till
                            </span>;

                        return (<React.Fragment key={cra.id}>
                            <div className={"customer_resp_auth_row " + (toggleCustomer[cra.id] ? 'active' : '')} onClick={() => this.toggleCustomerRow(hasResponsibleAuthorities, cra.id)}>
                                <span className="col-width-300 displayName">{cra.displayName}</span>
                                <span>{showRespAuthOrAdd}</span>
                            </div>

                            {showModal[cra.id] && <AdminAddCustomerRespAuthModal
                                customerId={cra.id}
                                customerResponsibleAuthorities={cra.responsibleAuthorities.map(ra => ra.id)}
                                displayName={cra.displayName}
                                close={(update) => this.closeModal(update)}
                            />}

                            {toggleCustomer[cra.id] &&
                                <React.Fragment>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Huvudman</th>
                                                <th></th>
                                                <th>Domäner</th>
                                                <th>Typ</th>
                                                <th>OrgNr</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cra.responsibleAuthorities.map(ra => {
                                                let domains = [ra.emailTopLevelDomainName, ra.responsibleAuthorityEmailTopLevelDomainName, ra.websiteTopLevelDomainName]
                                                    .filter(d => d !== null)
                                                    .filter((value, index, self) => self.indexOf(value) === index) //distinct
                                                    .join(', ');

                                                let hasSchoolUnits = ra.schoolUnits.length > 0;
                                                let showSchoolToggle = (hasSchoolUnits && !toggleSchools[ra.id]) ? 'Visa skolor' :
                                                    (hasSchoolUnits && toggleSchools[ra.id]) ? 'Dölj skolor' : ''

                                                return (<React.Fragment key={ra.id}>
                                                    <tr>
                                                        <td>{ra.title} <span className="small_red" onClick={() => this.toggleSchoolUnits(ra.id)}>{showSchoolToggle}</span></td>
                                                        <td className="td_saving"><span onClick={() => this.removeResponsibleAuthority(cra, ra.id, ra.title)}><FontAwesomeIcon icon="trash" title="Ta bort huvudman" className="remove-resposible-authority" /></span>
                                                            {saving[ra.id] && <Loading />}
                                                            {saved[ra.id] && <FontAwesomeIcon icon="check" className="add-resposible-authority-icon" />}
                                                        </td>
                                                        <td>{domains}</td>
                                                        <td>{ra.type}</td>
                                                        <td>{ra.peOrgNr}</td>
                                                    </tr>

                                                    {toggleSchools[ra.id] && <React.Fragment>
                                                        {ra.schoolUnits.map((rasu, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td className="pl-40">{rasu.title}</td>
                                                                    <td colSpan={4}>{rasu.schoolUnitCode}</td>
                                                                </tr>)
                                                        })}
                                                    </React.Fragment>}
                                                </React.Fragment>)
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                    <span onClick={() => this.addResponsibleAuthority(cra.id)} className="add-resposible-authority inside">
                                        <FontAwesomeIcon icon="plus-circle" className="add-resposible-authority-icon" /> Lägg till
                                    </span>
                                </React.Fragment>
                            }

                        </React.Fragment>)

                    })}
                </div>}

            </div>
        )
    }
}

const mapStatetoProps = state => ({
    adminToken: state.adminAuth.adminToken,
    customerResponsibleAuthority: state.responsibleAuthority.customerResponsibleAuthority
});

export default connect(mapStatetoProps, {
    AdminGetCustomerResposibleAuthority,
    AdminDeleteCustomerResposibleAuthority
})(AdminCustomerResponsibleAuthority);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../styles/Report.css';
import { GetReport } from '../store/ReportAction';
import Loading from './Loading';


class Report extends Component {

    componentDidMount() {
        let token = this.props.token;
        let id = this.props.match.params.id;
        this.props.GetReport(token, id)
    }

    runTime = (endDate, startDate) => {
        const sec_num = (endDate.getTime() - startDate.getTime()) / 1000;
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = Math.floor(sec_num - (hours * 3600) - (minutes * 60));
        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    }


    render() {
        const { report, fetchingReport } = this.props;

        if (!report || fetchingReport) {
            return <div id="report"><Loading /></div>
        }

        const endDate = report.stop ? new Date(report.stop) : null;
        const startDate = report.start ? new Date(report.start) : null;

        const environment = report.environment === 1 ? 'Produktion' : 'Test';

        return (
            <div id="report">
                <h1>{report.recipientDisplayName} Rapport</h1>
                <div>
                    <div className="report_table_1">
                        <div className="header">
                            <p>Kördes senast:</p>
                            <p>Körningen tog:</p>
                            <p>Miljö:</p>
                        </div>
                        <div>
                            <p>{endDate.toLocaleString()}</p>
                            <p>{this.runTime(endDate, startDate)}</p>
                            <p>{environment}</p>
                        </div>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Typ</th>
                                <th>Skapad</th>
                                <th>Uppdaterad</th>
                                <th>Borttagen</th>
                                <th>Fel</th>
                            </tr>
                        </thead>
                        <tbody>
                            {report.statistics &&
                                report.statistics.map(statistic => {
                                    const type =
                                        statistic.type === 'Organisation' ? 'Organisation' :
                                            statistic.type === 'SchoolUnit' ? 'Skolenhet' :
                                                statistic.type === 'User' ? 'Användare' :
                                                    statistic.type === 'Employment' ? 'Anställning' :
                                                        statistic.type === 'StudentGroup' ? 'Grupper' :
                                                            statistic.type === 'Activity' ? 'Aktiviteter' : statistic.type;
                                    return (
                                        <tr key={statistic.id}>
                                            <td>{type}</td>
                                            <td>{statistic.created}</td>
                                            <td>{statistic.updated}</td>
                                            <td>{statistic.deleted}</td>
                                            <td>{statistic.errors}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStatetoProps = state => ({
    token: state.auth.token,
    report: state.report.report,
    fetchingReport: state.report.fetchingReport
})

export default connect(mapStatetoProps, { GetReport })(Report);
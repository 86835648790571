import React, { Component } from 'react';
import Loading from './Loading';
import * as DownloadUtil from '../util/DownloadUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Download extends Component {
    state = {
        downloading: false,
        downloadFailed: false
    }

    download = (e) => {
        e.preventDefault();
        this.setState({ downloading: true, downloadFailed: false });
        DownloadUtil.download(this.props.href, { headers: { 'Authorization': 'Bearer ' + this.props.token }})
            .then(() => {
                this.setState({ downloading: false });
            })
            .catch((err) => {
                console.error('Failed to download file: ' + err);
                this.setState({ downloading: false, downloadFailed: true });
            });
    }

    render() {
        const { downloading, downloadFailed } = this.state;

        return (
            <div className={`export ${this.props.adminGui}`}>
                <a className="btn btn-default push--right"
                    onClick={this.download}
                    disabled={downloading}
                    href={this.props.href}
                ><FontAwesomeIcon icon="file-download" className="icon" /></a>
                {downloading && <Loading />}
                {downloadFailed && 'Misslyckades'}
            </div>
        );
    }
}

export default Download;

import React from 'react';
import '../styles/Loading.css';

const Loading = (props) =>

    <div className={props.classNameBg}>
        <div className={props.classNameInner + " spinner"}>
        </div>
    </div>

export default Loading;

import React, { Component } from 'react';
import '../styles/Register.css';
import { connect } from 'react-redux';
import { TopBar } from './TopBar';


//Todo
//Kolla texterna

class AcceptPermissions extends Component {

    Accept = () => {
        const redirectUri = '&redirect_uri=' + window.location.origin;
        window.location = this.props.consentUrl + redirectUri;
    }

    render() {
        const { errorCode } = this.props;

        return (
            <div className="register">
                <TopBar />
                <div className="register_inner">
                    {errorCode === 403 && <div>
                        <h1>Behörighet saknas</h1>
                        <p>För att godkänna behörigheterna för applikationen behöver du vara global admin.</p>
                    </div>}

                    {errorCode === 428 && <div>
                        <h1>Acceptera behörigheter</h1>

                        <p>För att registrera dig för Skolsynk så behöver du godkänna följande applikationsbehörigheter mot Microsoft Graph:</p>

                        <p><b>Läsa strukturen för organisationens skolor och undervisningsgrupper (EduRoster.Read.All)</b></p>
                        <ul>
                            <li>Används för att läsa ut skolor, undervisningsgrupper och medlemmar.</li>
                        </ul>

                        <p><b>Läsa medlemskapen i dolda grupper och administrativa enheter (Member.Read.Hidden)</b></p>
                        <ul>
                            <li>Krävs för att lista medlemmar i klasser.</li>
                        </ul>

                        <p><b>Läsa organisationen och relaterade resurser (Organization.Read.All)</b></p>
                        <ul>
                            <li>Används för att läsa ut organisationens namn.</li>
                        </ul>

                        <p><b>Läsa de rollbaserade åtkomstkontrollinställningarna (RBAC) för organisationens katalog (RoleManagement.Read.Directory)</b></p>
                        <ul>
                            <li>Används för att avgöra om den inloggade användaren är Global Administratör inom organisationen.</li>
                        </ul>

                        <p>För att godkänna behörigheterna för applikationen behöver du vara global administratör inom din organisation.</p>

                        <button className="login_btn" onClick={this.Accept}>Jag godkänner</button>
                    </div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    errorCode: state.auth.errorCode,
    consentUrl: state.auth.consentUrl
});

export default connect(mapStateToProps)(AcceptPermissions);

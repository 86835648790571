import React, { Component } from 'react';
import Loading from './Loading';
import SchoolUnitConfig from './SchoolUnitConfig';
import '../styles/SchoolUnit.css';

export default class SchoolUnits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipientSchoolUnits: props.recipientSchoolUnits,
            show: false
        };
    }

    onChange = (recipientSchoolUnit) => {
        const recipientSchoolUnits = [...this.state.recipientSchoolUnits];
        const inProps = this.props.recipientSchoolUnits.some(rsu => rsu.schoolUnitId === recipientSchoolUnit.schoolUnitId);
        const index = recipientSchoolUnits.findIndex(rsu => rsu.schoolUnitId === recipientSchoolUnit.schoolUnitId);

        if (index === -1) {
            recipientSchoolUnits.push(recipientSchoolUnit);
        } else if (!recipientSchoolUnit.active && !recipientSchoolUnit.schoolUnitCode && !inProps) {
            recipientSchoolUnits.splice(index, 1);
        } else {
            recipientSchoolUnits[index] = recipientSchoolUnit;
        }

        this.setState({ recipientSchoolUnits });
        this.props.onChange(recipientSchoolUnits);
    }

    renderList = (active) => {
        const { loading, schoolUnits, scbSchoolUnits } = this.props;
        const { recipientSchoolUnits } = this.state;
        const hasSchoolUnits = recipientSchoolUnits && schoolUnits && schoolUnits.length > 0;
        const schoolunitsToRender = schoolUnits.filter(su => su.active === active)
        
        if (loading) {
            return <Loading />;
        } else if (hasSchoolUnits) {
            return <div className={`schools`}>
                {schoolunitsToRender.map(schoolUnit =>
                    <SchoolUnitConfig
                        key={schoolUnit.id}
                        schoolUnit={schoolUnit}
                        recipientSchoolUnit={recipientSchoolUnits.find(rsu => rsu.schoolUnitId === schoolUnit.id)}
                        scbSchoolUnits={scbSchoolUnits}
                        onChange={(rsu) => this.onChange(rsu)}
                    />
                )}
            </div>;
        } else {
            return <div>Skolor saknas</div>;
        }
    }

    render() {
        let show = this.state.show;
        return <div className="m20 schoolunits">
            <div>
                <h2>Skolenheter</h2>
            </div>
            {this.renderList(true)}

            <div className="inactive_schoolunits">
                <h2>Tidigare inlästa skolenheter som nu är inaktiva hos Microsoft</h2>
                <span className="hideshow" onClick={() => this.setState({ show: !show })}>{show ? <p>Dölj</p> : <p>Visa</p>}</span>
            </div>
            <div className={`${show ? 'show' : 'hide'}`}>
                {this.renderList(false)}
            </div>
        </div>;
    }
}


import React, { Component } from 'react';
import NavMenu from './NavMenu';
import { TopBar } from './TopBar';
import '../styles/Layout.css';


export class Layout extends Component {

    render() {
        return (
            <div className="layout">
                <TopBar />
                <div className="inner_layout">
                    <NavMenu />
                </div>
                <div className="children">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

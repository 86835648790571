import React, { Component } from 'react';
import '../styles/TopBar.css';
import { Link } from 'react-router-dom';


export class TopBar extends Component {

    render() {
        return (
            <div className="topbar">
                <div className="topbar_brand"><Link to="/"><img className="logo" src="../Images/logo_neg.png" alt="Skolsynk" /></Link></div>
                <span className="logo_text">Microsoft</span>
            </div>
        );
    }
}